<template>
  <div>
    <iframe width="100%" height="600px" :src="mediaLocation"></iframe>
  </div>
</template>

<script>
export default {
  name: 'DongjiaoComponent',
  data () {
    return ({
      mediaLocation: ''
    })
  },
  methods: {
    showLocation (resourceCode) {
      // let poiId = 3321
      // if (deviceId % 2 > 0) {
      //   poiId = 3322
      // }
      this.mediaLocation = 'http://110.41.40.147:8998/sdxk-api/vision/common/position?siteId=529757&poiId=' + resourceCode
    }
  }
}
</script>
