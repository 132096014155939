<template>
  <div>
    <template v-if="categoryId === 4">
      <ThemeInfo :resourceId="resourceId" :isManage="isManage"/>
    </template>

    <template v-else>
      <h4 class="workplatform-title m-b-10">媒体属性</h4>
      <component :is="attributeComponent" :resourceModel="resourceModel"></component>

      <h3 class="workplatform-title m-t-10">图例描述</h3>
      <Tabs v-model="curTab">
        <TabPane label="位置展示" name="name1">
          <dong-jiao-component  v-if="publisherId===29" ref="dongjiao"/>
          <resource-svg-component v-else-if="categoryId !== 21" ref="resourceSvg" class="p-t-10"/>
          <p v-else class="text-center p-t-20 text-16 text-gray" >暂无位置信息</p>
        </TabPane>
        <TabPane label="示意图" name="name2">
          <Row>
            <i-col span="8" v-for="(img,index) in resourceImgs" :key="index" class="p-b-20">
              <div v-viewer class="images clearfix">
                <img :src="img.ossLocation" style="cursor: pointer;" class="image" width="250">
              </div>
            </i-col>
          </Row>
          <p v-if="resourceImgs.length===0" class="text-center p-t-20 text-16 text-gray" >暂无数据</p>
        </TabPane>
      </Tabs>

    </template>
  </div>
</template>

<script>
import { getResourceById } from '@/api/product/productresource'
import { getResourceSvg } from '@/api/product/stationgraph'
import { getPhotoBySkuid } from '@/api/product/productskuedit'

import ResourceSvgComponent from '@/components/svg/SvgResource'
import DongJiaoComponent from '@/components/svg/Dongjiao'
import ThemeInfo from '../resourceComponent/ThemeInfo'

export default {
  props: {
    resourceId: {
      type: Number,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },
    isManage: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ResourceSvgComponent,
    DongJiaoComponent,
    ThemeInfo,
    defaultComponent: () => import('../resourceComponent/LightboxAttribute'),
    brandComponent: () => import('../resourceComponent/BrandAttribute'),
    trainComponent: () => import('../resourceComponent/TrainAttribute')
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      attributeComponent: '',
      curTab: 'name1',
      resourceImgs: [], // 图片集合

      resourceModel: {
        assetId: 0,
        assetName: '',
        code: '',
        enabled: null,
        exit: '',
        explain: '',
        finalheight: 0,
        finalwidth: 0,
        id: 0,
        layer: 0,
        lockState: null,
        makeheight: 0,
        makewidth: 0,
        position: null,
        positionName: '',
        resourcetypeId: null,
        resourcetypeName: '',
        shortperiodAllowed: null,
        stationId: 0,
        stationLevel: 0,
        stationLevelName: '',
        stationName: ''
      }
    }
  },
  created () {
    if (this.categoryId !== 4) {
      this.loadComponent()
      this.initData()
    }
  },
  methods: {
    loadComponent () {
      if (this.categoryId === 2) {
        this.attributeComponent = 'trainComponent'
      } else if (this.categoryId === 3) {
        this.attributeComponent = 'brandComponent'
      } else {
        this.attributeComponent = 'defaultComponent'
      }
    },
    initData () {
      const data = { resourceId: this.resourceId }
      getResourceById(data).then(res => {
        this.resourceModel = res

        if (this.publisherId === 29) {
          this.$refs.dongjiao.showLocation(res.code)
        } else if (this.categoryId !== 21) { // 路面媒体或者园区媒体不加载SVG
          this.loadResourceSvg(res.deviceId)
        }

        this.loadResourceImg(res.resourceId)
      })
    },
    loadResourceSvg (deviceId) {
      const postData = {
        resourceId: this.resourceId,
        isRemove: true
      }
      getResourceSvg(postData).then(res => {
        this.$refs.resourceSvg.bindLightBoxData(res, deviceId)
      })
    },
    // 根据用户实际情况，示意图使用不多，暂时屏蔽
    loadResourceImg (resourceId) {
      getPhotoBySkuid({ resourceId: resourceId }).then(res => {
        this.resourceImgs = res
      })
    }
  }
}
</script>
