export function getDetailColumns (categoryId) {
  let result
  if (categoryId === 2) {
    // 列车
    result = trainColumns()
  } else if (categoryId === 3) {
    // 梯牌
    result = brandColumns()
  } else if (categoryId === 4) {
    // 主题媒介
    result = themeColumns()
  } else if (categoryId === 21) {
    // 园区媒介
    result = parkColumns()
  } else if (categoryId === 8) {
    // 电子媒体
    result = electronicColumns()
  } else {
    result = defaultColunms()
  }

  return result
}

function defaultColunms () {
  return [
    {
      title: '资源编号',
      align: 'center',
      width: 120,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.code)
      }
    },
    {
      title: '所属站点',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.stationName)
      }
    },
    {
      title: '所属楼层',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.floor)
      }
    },
    {
      title: '位置',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.positionName)
      }
    }
  ]
}
function trainColumns () {
  return [{
    title: '列车编号',
    align: 'center',
    width: 140,
    fixed: 'left',
    render: (h, params) => {
      return h('span', params.row.productResourceBean.code)
    }
  },
  {
    title: '列车类型',
    align: 'center',
    width: 120,
    render: (h, params) => {
      return h('span', params.row.productResourceBean.stationName)
    }
  }]
}
function brandColumns () {
  return [{
    title: '所属站点',
    align: 'center',
    width: 160,
    fixed: 'left',
    render: (h, params) => {
      return h('span', params.row.productResourceBean.stationName)
    }
  },
  {
    title: '所在出口',
    align: 'center',
    width: 100,
    render: (h, params) => {
      return h('span', params.row.productResourceBean.exit)
    }
  }]
}
function themeColumns () {
  return [
    {
      title: '单品名称',
      align: 'center',
      width: 200,
      render: (h, params) => {
        return h('span', params.row.productskufixBean ? params.row.productskufixBean.productskuName : '')
      }
    }
  ]
}
function parkColumns () {
  return [
    {
      title: '资源编号',
      align: 'center',
      width: 120,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.code)
      }
    },
    {
      title: '所属站点',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.stationName)
      }
    },
    {
      title: '媒介类型',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.resourcetypeName)
      }
    },
    {
      title: '位置',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.positionName)
      }
    }
  ]
}
function electronicColumns () {
  return [
    {
      title: '资源编号',
      align: 'center',
      width: 120,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.code)
      }
    },
    {
      title: '所属站点',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.stationName)
      }
    },
    {
      title: '所属楼层',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.floor)
      }
    },
    {
      title: '位置',
      align: 'center',
      width: 100,
      render: (h, params) => {
        return h('span', params.row.productResourceBean.positionName)
      }
    },
    {
      title: '可用库存/总库存',
      align: 'center',
      width: 120,
      render: (h, params) => {
        return h('span', params.row.surplusStock + ' / ' + params.row.productResourceBean.stock)
      }
    }
  ]
}
