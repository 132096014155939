import request from '@/utils/requestV2'
const qs = require('qs')

// 通过站点ID获取所属的SVG信息
export function getStationSvgByStationId (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getStationSvgByStationId',
    method: 'POST',
    data: qs.stringify(data),
    timeout: 200000
  })
}
// 通过站点ID获取所属的SVG原图
export function getStationSvg (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getStationSVG',
    method: 'POST',
    data: qs.stringify(data),
    timeout: 200000
  })
}
// 通过resourceId获取对应的SVG信息
export function getResourceSvg (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getresourcesvg',
    method: 'POST',
    data: qs.stringify(data),
    timeout: 200000
  })
}
// 通过主题媒介查看svg
export function getSkuSvgList (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getskusvglist',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 新增svg分层
export function addSvgLevel (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/addsvglevel',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 删除svg分层
export function deleteSvgLevel (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/deletesvglevel',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取svg分层
export function getSvgLevel (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getsvglevel',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取svg元素列表
export function getSvgElement (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getsvgelement',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取svg元素层级关系
export function getSvgElementLevel (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getsvgelementlevel',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 保存元素分层关系
export function addSvgElementLevel (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/addsvgelementlevel',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 通过deviceId获取对应的SVG信息
export function getDeviceSvg (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getdevicesvg',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取站点的3D图
export function getStation3dByStationId (data) {
  return request({
    url: '/ooh-product/v1/stationgraph/getStation3dByStationId',
    method: 'POST',
    data: qs.stringify(data)
  })
}
