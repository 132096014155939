import request from '@/utils/requestV2'
const qs = require('qs')

// 根据订单获取产品下资源明细分页
export function getProductDetailPageByOrder (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单内站点数量统计 按站点分组
export function getResourceCountByOrderId (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getresourcecount',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单资源资产、站点、位置（前端下拉菜单）
export function getResourceParameter (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getresourceparameter',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 资源全部售卖记录
export function getResourceRecords (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getresourcerecords',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取站内资源的锁位到期时间
export function getResourceExpiretime (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getresourceexpiretime',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单资源类型尺寸统计
export function getResourceTypeQuantity (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getresourcetypequantity',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 站点内售出资源品牌和档期
export function getSoldResourceInfo (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getsoldresourceinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单内站点集合 按资产分组
export function getStationLevelList (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getstationlevellist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单明细产品站点和数量 按资产分组
export function getRoadStationList (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getstationlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// ---------------业主订单------------------
// 获取业主订单明细
export function getOwnerOrderDetailPage (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getOwnerOrderDetailPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 业主获取订单内站点集合 按资产分组
export function getOwnerstationlevellist (data) {
  return request({
    url: '/ooh-order/v1/productdetail/getOwnerstationlevellist',
    method: 'post',
    data: qs.stringify(data)
  })
}
