<template>
  <div>
    <Row :gutter="4">
        <i-col :xs="24" :sm="12" :md="6" :lg="3" v-show="queryStationShow" class="m-b-5">
            <Select size="small" v-model="query.stationId" :placeholder="categoryId===2?'列车类型':'站点'" :clearable="true">
                <Option v-for="item in stationList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="3" v-show="queryPositionShow" class="m-b-5">
            <Select size="small" v-model="query.position" placeholder="位置" :clearable="true">
                <Option v-for="item in positionList" :value="item.value" :key="item.value">{{ item.name }}</Option>
            </Select>
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
            <Select size="small" v-model="query.enabled" placeholder="是否可用" :clearable="true">
                <Option v-for="item in enabledList" :value="item.value" :key="item.value">{{ item.name }}</Option>
            </Select>
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="3" v-show="querySoldShow" class="m-b-5">
            <Select size="small" v-model="query.soldStatus" multiple placeholder="库存状态" :clearable="true">
                <Option v-for="item in soldList" :value="item.value" :key="item.value">{{ item.name }}</Option>
            </Select>
        </i-col>
        <i-col :xs="24" :sm="12" :md="8" :lg="6" class="m-b-5">
            <Input size="small" type="text" v-model="query.keyword" :placeholder="searchPlaceholder" />
        </i-col>
        <i-col :xs="24" :sm="12" :md="6" :lg="6" class="m-b-5">
            <Button size="small" icon="ios-search" type="primary" @click="handleSearch">搜索</Button>
            <Button size="small" class="m-l-5" type="success" @click="handleExport">导出资源清单</Button>
        </i-col>
    </Row>

    <Row :gutter="16" class="m-b-2" v-if="showSaleTimeline">
      <i-col span="24">
          <Tag color="#534BA5" style="width:80px;text-align:center">已售</Tag>
          <Tag color="#E86725" style="width:80px;text-align:center">预定</Tag>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { getPositions } from '@/api/product/resource'

export default {
  props: {
    showSaleTimeline: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return ({
      query: {
        stationId: null,
        position: null,
        soldStatus: [],
        enabled: 1,
        keyword: ''
      },
      stationList: [], // 站点清单
      positionList: [], // 资源站点位置
      enabledList: [
        { value: 0, name: '不可用' },
        { value: 1, name: '可用' }
      ],
      soldList: [
        { value: 0, name: '空位' },
        { value: 1, name: '已售出' },
        { value: 2, name: '预定中' }
      ],
      searchPostionQuery: {
        assetIds: '',
        productId: 0
      }
    })
  },
  methods: {
    initPageData () {
      this.query = {
        stationId: null,
        position: null,
        soldStatus: [],
        enabled: 1,
        keyword: ''
      }

      this.initStationList()
      this.getPostionDictList()
    },
    initStationList () {
      if (!this.queryStationShow) { return }

      // 显示全部资源
      if (this.mapType === 'resource') {
        this.query.stationId = null
        this.stationList = this.stations.map(x => {
          return { id: x.stationId, name: x.stationName }
        })
      } else if (this.mapType === 'flicker') { // 闪烁显示单个站点
        this.query.stationId = this.stations[0]
      }
    },
    getPostionDictList () { // 获取资源包含位置
      if (!this.queryPositionShow) { return }

      const assetIds = this.searchCondition.assetIds
      const productId = this.searchCondition.productId

      if (this.searchPostionQuery.assetIds !== assetIds || this.searchPostionQuery.productId !== productId) {
        this.searchPostionQuery.assetIds = assetIds
        this.searchPostionQuery.productId = productId

        getPositions(this.searchPostionQuery).then(res => {
          this.positionList = res
        })
      }
    },
    handleSearch () {
      this.$emit('parentMethod', this.query)
    },
    handleExport () {
      this.$emit('parentExport', this.query)
    }
  },
  computed: {
    stations () {
      return this.$store.state.stock.stations
    },
    mapType () {
      return this.$store.state.stock.mapType
    },
    searchCondition () {
      return this.$store.state.stock.searchCondition
    },
    categoryId () {
      return this.$store.state.stock.categoryId
    },
    beginUpdate () {
      return this.$store.state.stock.beginUpdate
    },
    queryStationShow () {
      // 地体列车和主题媒介不显示站点
      return ![2, 4].includes(this.categoryId)
    },
    queryPositionShow () {
      // 地铁列车、梯牌、主题媒介、地铁语音
      return ![2, 3, 4, 9].includes(this.categoryId)
    },
    querySoldShow () {
      // 电子屏和点播屏
      return ![13, 16].includes(this.categoryId)
    },
    searchPlaceholder () {
      let text = '关键词（资源编号、站点编号、'
      text += this.categoryId === 4 ? '单品名称' : '站点名称'
      text += '）'

      return text
    }
  },
  watch: {
    beginUpdate (val) {
      this.initPageData()
    }
  }
}
</script>
