export const resSearchMixin = {
  data () {
    return {
      saleTimeline: false,
      dataList: [],
      dataColumns: [],
      columns1: [],
      columns2: [],
      columns3: []
    }
  },
  methods: {
    /**
       * 组装展示数据
       */
    fnAssemblyData () {
      let columns = [...this.columns1]
      if (!this.saleTimeline) {
        columns = columns.concat(this.columns2)
        this.dataColumns = columns
        return false
      }

      this.columns3 = []
      let dateList = []
      // let colorRows = [] // 需要着色行索引集合
      // 筛选需要展示的日期标题
      this.dataList.filter(x => x.reserveSaleInfoBeanList.length > 0).forEach(item => {
        dateList = dateList.concat(item.reserveSaleInfoBeanList)
      })
      this.dataList.filter(x => x.publishSaleInfoBeanList.length > 0).forEach(item => {
        dateList = dateList.concat(item.publishSaleInfoBeanList)
      })
      const startDateArray = dateList.map(x => x.startSchedule)
      const endDateArray = dateList.map(x => x.endSchedule)
      let dateArray = Array.from(new Set([...startDateArray, ...endDateArray])).sort()
      // 删除不符合查询日期的数据
      dateArray = dateArray.filter(x => {
        if (new Date(this.startDate) <= new Date(x) && new Date(x) <= new Date(this.endDate)) {
          return x
        }
      })
      this.dataList.forEach(item => {
        item.cellClassName = {}
        // item.keyDate = []
        // if (item.publishSaleInfoBeanList.length > 0) {
        //   colorRows.push(index)
        // }
        // if (item.reserveSaleInfoBeanList.length > 0) {
        //   colorRows.push(index)
        // }
        // this.changeRows = colorRows

        dateArray.forEach(date => {
          // let colObj = {
          //   colKey: '',
          //   colspan: 1
          // }
          item.publishSaleInfoBeanList.forEach(child => {
            if (new Date(child.startSchedule) <= new Date(date) && new Date(date) <= new Date(child.endSchedule)) {
              Object.defineProperty(item.cellClassName, date, {
                value: 'demo-table-info-cell-sold',
                writable: true,
                enumerable: true,
                configurable: true
              })
            }
            // if (child.startSchedule === date) {
            //   colObj.colspan = dateArray.filter(d => new Date(child.startSchedule) <= new Date(d) && new Date(d) <= new Date(child.endSchedule)).length
            //   colObj.colKey = date
            //   item.keyDate.push(colObj)
            // }
          })
          // tempIndex = index2
          // item.lockSaleInfoBeanList.forEach(child => {
          //   if (new Date(child.startSchedule) <= new Date(date) && new Date(date) <= new Date(child.endSchedule)) {
          //     Object.defineProperty(item.cellClassName, date, {
          //       value: 'demo-table-info-cell-book',
          //       writable: true,
          //       enumerable: true,
          //       configurable: true
          //     })
          //   }
          //   // if (child.startSchedule === date) {
          //   //   colObj.colspan = dateArray.filter(d => new Date(child.startSchedule) <= new Date(d) && new Date(d) <= new Date(child.endSchedule)).length
          //   //   colObj.colKey = date
          //   //   item.keyDate.push(colObj)
          //   // }
          // })
          item.reserveSaleInfoBeanList.forEach(child => {
            if (new Date(child.startSchedule) <= new Date(date) && new Date(date) <= new Date(child.endSchedule)) {
              Object.defineProperty(item.cellClassName, date, {
                value: 'demo-table-info-cell-book',
                writable: true,
                enumerable: true,
                configurable: true
              })
            }
          })
        })
      })
      dateArray.forEach(item => {
        const column = {
          title: item,
          align: 'center',
          width: dateArray.length > 5 ? 200 : null,
          key: item,
          render: (h, param) => {
            const divs = []
            // if (this.isManage && param.row.reserveSaleInfoBeanList !== null && param.row.reserveSaleInfoBeanList.length > 0 && param.row.reserveSaleInfoBeanList.some(x => new Date(x.startSchedule) <= new Date(item)) && param.row.reserveSaleInfoBeanList.some(x => new Date(item) <= new Date(x.endSchedule))) {

            param.row.publishSaleInfoBeanList.forEach(child => {
              if (new Date(child.startSchedule) <= new Date(item) && new Date(item) <= new Date(child.endSchedule)) {
                divs.push(h('p', child.customerName))
              }
            })
            // param.row.lockSaleInfoBeanList.forEach(child => {
            //   if (new Date(child.startSchedule) <= new Date(item) && new Date(item) <= new Date(child.endSchedule)) {
            //     divs.push(h('p', child.customerName))
            //   }
            // })
            param.row.reserveSaleInfoBeanList.forEach(child => {
              if (new Date(child.startSchedule) <= new Date(item) && new Date(item) <= new Date(child.endSchedule)) {
                divs.push(h('p', child.customerName))
              }
            })
            return h('div',
              {
                style: { cursor: 'pointer' },
                on: {
                  click: () => {
                    this.chooseResourceId = param.row.productResourceBean ? param.row.productResourceBean.resourceId : param.row.productskufixBean.productskuId
                    this.showRecordModal = true
                  }
                }
              }, divs

            )
            // }
          }
        }
        this.columns3.push(column)
      })

      columns = columns.concat(this.columns3)
      this.dataColumns = columns
    }
  }
}
