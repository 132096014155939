<template>
  <div>
    <Table stripe size="small" :data="list" :columns="dataColumns"></Table>

    <Modal v-model="showDetailsModal" width="1000" footer-hide :styles="{top: '40px'}">
      <resourceDetail v-if="showDetailsModal" :resourceId="chooseResourceId" :categoryId="categoryId" :isManage="isManage"/>
    </Modal>

    <Modal v-model="showRecordModal" width="1000" footer-hide>
      <resourceRecord v-if="showRecordModal" :resourceId="chooseResourceId" :categoryId="categoryId" :isManage="isManage"
        :deviceId="chooseDeviceId" :startDate="startDate" :endDate="endDate"/>
    </Modal>
  </div>
</template>

<script>
import resourceDetail from './Detail'
import resourceRecord from './Record'
import { resSearchMixin } from '@/assets/mixins/res-search'
import { getDetailColumns } from './generateDetailColumn'

export default {
  mixins: [resSearchMixin],
  props: {
    list: {
      requried: true,
      type: Array
    },
    isManage: {
      requried: true,
      type: Boolean
    },
    categoryId: {
      requried: true,
      type: Number
    },
    showSaleTimeline: { // 显示售卖时间轴
      type: Boolean,
      default: false
    },
    startDate: [String], // 时间轴在JS类中使用
    endDate: [String] // 时间轴在JS类中使用
  },
  components: {
    resourceDetail, resourceRecord
  },
  data () {
    return ({
      showDetailsModal: false,
      showRecordModal: false,
      chooseResourceId: 0, // 当前选中的资源ID
      chooseDeviceId: 0, // 当前选中的资源设备ID
      columns1: [], // 动态详情列
      columns2: [ // 售卖记录，包括预定、已售、锁位
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('div', [
              this.categoryId === 9 ? ''
                : h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      // 主题媒体和常规取值不一样
                      this.chooseResourceId = params.row.productResourceBean ? params.row.productResourceBean.resourceId : params.row.productskufixBean.productskuId
                      this.showDetailsModal = true
                    }
                  }
                }, '详情'),
              h('a', {
                on: {
                  click: () => {
                    this.chooseResourceId = params.row.productResourceBean ? params.row.productResourceBean.resourceId : params.row.productskufixBean.productskuId
                    this.chooseDeviceId = params.row.productResourceBean ? params.row.productResourceBean.deviceId : 0
                    this.showRecordModal = true
                  }
                }
              }, '售卖记录')
            ]
            )
          }
        },
        {
          title: '当前发布客户',
          align: 'center',
          width: 260,
          render: (h, params) => {
            if (this.isManage && params.row.publishSaleInfoBeanList !== null && params.row.publishSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.publishSaleInfoBeanList.map(item => {
                  return h('p', item.customerName)
                })
              )
            }
          }
        },
        {
          title: '品牌',
          align: 'center',
          width: 160,
          render: (h, params) => {
            if (this.isManage && params.row.publishSaleInfoBeanList !== null && params.row.publishSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.publishSaleInfoBeanList.map(item => {
                  return h('p', item.brand)
                })
              )
            }
          }
        },
        {
          title: '所属销售',
          align: 'center',
          width: 100,
          render: (h, params) => {
            if (this.isManage && params.row.publishSaleInfoBeanList !== null && params.row.publishSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.publishSaleInfoBeanList.map(item => {
                  return h('p', item.saleName)
                })
              )
            }
          }
        },
        {
          title: '已售档期',
          align: 'center',
          width: 200,
          render: (h, params) => {
            if (params.row.publishSaleInfoBeanList !== null && params.row.publishSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.publishSaleInfoBeanList.map(item => {
                  return h('p', item.startSchedule + ' 至 ' + item.endSchedule)
                })
              )
            }
          }
        },
        {
          title: '发布天数',
          align: 'center',
          width: 100,
          render: (h, params) => {
            if (params.row.publishSaleInfoBeanList !== null && params.row.publishSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.publishSaleInfoBeanList.map(item => {
                  return h('p', item.dayCount)
                })
              )
            }
          }
        },
        {
          title: '发布类型/购买形式',
          align: 'center',
          width: 140,
          render: (h, params) => {
            if (params.row.publishSaleInfoBeanList !== null && params.row.publishSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.publishSaleInfoBeanList.map(item => {
                  return h('p', item.publishType)
                })
              )
            }
          }
        },
        {
          title: '当前锁位客户',
          align: 'center',
          width: 260,
          render: (h, params) => {
            if (this.isManage && params.row.lockSaleInfoBeanList !== null && params.row.lockSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.lockSaleInfoBeanList.map(item => {
                  return h('p', item.customerName)
                })
              )
            }
          }
        },
        {
          title: '品牌',
          align: 'center',
          width: 160,
          render: (h, params) => {
            if (this.isManage && params.row.lockSaleInfoBeanList !== null && params.row.lockSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.lockSaleInfoBeanList.map(item => {
                  return h('p', item.brand)
                })
              )
            }
          }
        },
        {
          title: '所属销售',
          align: 'center',
          width: 100,
          render: (h, params) => {
            if (this.isManage && params.row.lockSaleInfoBeanList !== null && params.row.lockSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.lockSaleInfoBeanList.map(item => {
                  return h('p', item.saleName)
                })
              )
            }
          }
        },
        {
          title: '锁位档期',
          align: 'center',
          width: 200,
          render: (h, params) => {
            if (params.row.lockSaleInfoBeanList !== null && params.row.lockSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.lockSaleInfoBeanList.map(item => {
                  return h('p', item.startSchedule + ' 至 ' + item.endSchedule)
                })
              )
            }
          }
        },
        {
          title: '发布天数',
          align: 'center',
          width: 100,
          render: (h, params) => {
            if (params.row.lockSaleInfoBeanList !== null && params.row.lockSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.lockSaleInfoBeanList.map(item => {
                  return h('p', item.dayCount)
                })
              )
            }
          }
        },
        {
          title: '发布类型/购买形式',
          align: 'center',
          width: 140,
          render: (h, params) => {
            if (params.row.lockSaleInfoBeanList !== null && params.row.lockSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.lockSaleInfoBeanList.map(item => {
                  return h('p', item.publishType)
                })
              )
            }
          }
        },
        {
          title: '当前预定客户',
          align: 'center',
          width: 260,
          render: (h, params) => {
            if (this.isManage && params.row.reserveSaleInfoBeanList !== null && params.row.reserveSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.reserveSaleInfoBeanList.map(item => {
                  return h('p', item.customerName)
                })
              )
            }
          }
        },
        {
          title: '品牌',
          align: 'center',
          width: 160,
          render: (h, params) => {
            if (this.isManage && params.row.reserveSaleInfoBeanList !== null && params.row.reserveSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.reserveSaleInfoBeanList.map(item => {
                  return h('p', item.brand)
                })
              )
            }
          }
        },
        {
          title: '所属销售',
          align: 'center',
          width: 100,
          render: (h, params) => {
            if (this.isManage && params.row.reserveSaleInfoBeanList !== null && params.row.reserveSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.reserveSaleInfoBeanList.map(item => {
                  return h('p', item.saleName)
                })
              )
            }
          }
        },
        {
          title: '预定档期(先签先得)',
          align: 'center',
          width: 200,
          render: (h, params) => {
            if (params.row.reserveSaleInfoBeanList !== null && params.row.reserveSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.reserveSaleInfoBeanList.map(item => {
                  return h('p', item.startSchedule + ' 至 ' + item.endSchedule)
                })
              )
            }
          }
        },
        {
          title: '预定天数',
          align: 'center',
          width: 100,
          render: (h, params) => {
            if (params.row.reserveSaleInfoBeanList !== null && params.row.reserveSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.reserveSaleInfoBeanList.map(item => {
                  return h('p', item.dayCount)
                })
              )
            }
          }
        },
        {
          title: '备注',
          align: 'center',
          width: 140,
          render: (h, params) => {
            if (params.row.publishSaleInfoBeanList !== null && params.row.publishSaleInfoBeanList.length > 0) {
              return h('div',
                params.row.publishSaleInfoBeanList.map(item => {
                  return h('p', item.desc)
                })
              )
            }
          }
        }
      ],
      columns3: [], // 售卖时间轴
      dataColumns: []
    })
  },
  created () {
    if (!this.isManage) { // 如果不是管理界面，那么移除掉销售信息列
      this.columns2.splice(1, 3)
      this.columns2.splice(4, 3)
      this.columns2.splice(7, 3)
    }
  },
  watch: {
    showSaleTimeline (val) {
      this.saleTimeline = val
    },
    list (val) {
      this.saleTimeline = this.showSaleTimeline
      this.dataList = val
      this.columns1 = getDetailColumns(this.categoryId)
      this.fnAssemblyData()
    }
  }
}
</script>
